/**
 * Creates a debounced function that delays invoking the provided function until at least ms milliseconds have elapsed.
 * @param func Function to debounce
 * @param wait Delay to trigger function in ms (default: 300)
 * @returns (function)
 */
export const debounce = (func: Function, wait: number = 300) => {
  let timer: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), wait);
  };
};

/**
 * Creates a throttled function that only invokes the provided function at most once per every wait (default: 300) milliseconds.
 * @param func Function triggered after wait
 * @param wait Delay to trigger function in ms (default: 300)
 * @returns (function)
 */
export const throttle = (func: Function, wait: number = 300) => {
  let throttled: boolean,
    lastFn: ReturnType<typeof setTimeout>,
    lastTime: number;

  return function (this: any) {
    const context = this,
      args = arguments;

    if (!throttled) {
      func.apply(context, args);
      // Use Date.now() to keep track of the last time the throttled function was invoked.
      lastTime = Date.now();
      throttled = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          func.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

// returns CSRF Token
export const getCSRFToken = async () => {
  try {
    const response = await fetch('/libs/granite/csrf/token.json');
    const json = await response.json();
    return json.token;
  } catch (e) {
    console.warn(e);
    return '';
  }
};
