(() => {
  const initStepForm = ($form: HTMLFormElement) => {
    let $totalStepCountEl: HTMLInputElement,
      $nextCTA: HTMLButtonElement,
      $submitCTA: HTMLButtonElement,
      $prevCTA: HTMLButtonElement,
      $allTextFields: NodeListOf<HTMLInputElement>,
      $allStepFields: NodeListOf<HTMLElement>,
      $loader: HTMLElement,
      $successMsg: HTMLElement,
      $errorMsg: HTMLElement,
      $allFields: NodeListOf<HTMLElement>,
      formId = $form.id,
      nextStepSelector = 'step-form__show-next',
      submitStepSelector = 'step-form__show-submit',
      formStepClass = 'step-form__showing-step-',
      stepElClass = 'step-form__step-',
      stepElMainClass = 'step-form__el',
      showStepElClass = 'step-form__el--show',
      prevStepElClass = 'step-form__el--prev',
      disabledBtnClass = 'btn--disabled',
      showSubmitCTAClass = 'u-step-form--showing-submit',
      showNextCTAClass = 'u-step-form--showing-next',
      showPrevCTAClass = 'u-step-form--showing-prev',
      showSuccessMsgClass = 'u-step-form--showing-success',
      showErrorMsgClass = 'u-step-form--showing-error',
      loaderActiveClass = 'loader--active',
      disabledFormElClass = 'u-form-element--disabled',
      dataStepAttr = 'data-step',
      endpoint: string,
      totalSteps: number,
      currentStep: number,
      successMsgShowTime: number,
      successTimeout: any,
      showingError: boolean = false;

    // when enter is pressed inside a text field, the form gets submitted.
    // avoiding this by triggering preventDefault when enter is pressed
    const disableEnterInTextFields = () => {
      $allTextFields.forEach(($field: HTMLInputElement) => {
        $field.addEventListener('keydown', e => {
          //@ts-ignore
          if (e.key.toLowerCase() === 'enter') {
            e.preventDefault();
          }
        });
      });
    };

    // adds a class name like step-form__showing-step-1 to the form
    // count is the no. of step that the form is currently at
    const addStepClassNameToForm = (count: number) => {
      let curClassname;
      $form.classList.forEach(classname => {
        if (classname.indexOf(formStepClass) >= 0) {
          curClassname = classname;
        }
      });

      if (curClassname) {
        $form.classList.remove(curClassname);
      }

      $form.classList.remove(formStepClass + count);
    };

    // shows only the elements that are corresponding to the current step of the form
    // adds active class to the elements that are supposed to be shown
    // removes the same class from the elements that are supposed to be hidden
    // adds prev step class to the elements that are in previous class
    const showOrHideElementsOfStep = (count: number) => {
      $allStepFields.forEach($el => {
        $el.classList.remove(showStepElClass, prevStepElClass);
      });

      let $allPrevStepFields: HTMLElement[] = [];

      for (let i = 1; i < count; i++) {
        const $els = Array.from(
          $form.querySelectorAll(`.${stepElClass}${i}`)
        ) as HTMLElement[];

        if ($els?.length) {
          $allPrevStepFields = $allPrevStepFields.concat($els);
        }
      }

      $allPrevStepFields.forEach($prevStepEl => {
        $prevStepEl.classList.add(prevStepElClass);
      });

      const $curStepFields = $form.querySelectorAll(
        `.${stepElClass}${count}`
      ) as NodeListOf<HTMLElement>;
      $curStepFields.forEach($el => {
        $el.classList.add(showStepElClass);
      });
    };

    // shows/hides and enables/disables the ctas based on the current step of the form
    const showOrHideNextOrSubmitCtas = () => {
      let showSubmit = false;
      let enableSubmitCta = false;
      let enableNextCta = false;
      let flagsSet = false;

      // getting all the elements in the current step, with next steps class
      const $nextStepFields = $form.querySelectorAll(
        `.${nextStepSelector}.${stepElClass}${currentStep} input, .${nextStepSelector}.${stepElClass}${currentStep} select, .${nextStepSelector}.${stepElClass}${currentStep} textarea`
      ) as NodeListOf<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >;

      // getting all the elements in the current step, with submit steps class
      const $submitStepFields = $form.querySelectorAll(
        `.${submitStepSelector}.${stepElClass}${currentStep} input, .${submitStepSelector}.${stepElClass}${currentStep} select, .${submitStepSelector}.${stepElClass}${currentStep} textarea`
      ) as NodeListOf<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >;

      // getting all the elements in the current step, with required checked
      const $reqStepFields = $form.querySelectorAll(
        `[data-required="true"].${stepElClass}${currentStep} input, [data-required="true"].${stepElClass}${currentStep} select, [data-required="true"].${stepElClass}${currentStep} textarea`
      ) as NodeListOf<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >;

      const submitFieldsValidityArr = getValidityArr(
        $submitStepFields
      ) as Boolean[];
      const nextFieldsValidityArr = getValidityArr(
        $nextStepFields
      ) as Boolean[];
      const reqFieldsValidityArr = getValidityArr($reqStepFields) as Boolean[];

      // all required elements need to be valid
      const reqStepsValid =
        $reqStepFields?.length > 0 &&
        reqFieldsValidityArr.some(e => e === false) === false;

      // at least one submit step should be valid
      const submitStepsValid =
        $submitStepFields?.length > 0 &&
        submitFieldsValidityArr.some(e => e === true);
      // at least one next step should be valid
      const nextStepsValid =
        $nextStepFields?.length > 0 &&
        nextFieldsValidityArr.some(e => e === true);

      if (submitStepsValid) {
        flagsSet = true;
        showSubmit = true;
        if (
          ($reqStepFields?.length > 0 && reqStepsValid) ||
          $reqStepFields.length === 0
        ) {
          enableSubmitCta = true;
        } else {
          enableSubmitCta = false;
        }
      } else {
        showSubmit = false;
        enableSubmitCta = false;
      }

      if (nextStepsValid && flagsSet !== true) {
        showSubmit = false;
        flagsSet = true;
        if (
          ($reqStepFields?.length > 0 && reqStepsValid) ||
          $reqStepFields.length === 0
        ) {
          enableNextCta = true;
        } else {
          enableNextCta = false;
        }
      } else if (flagsSet !== true) {
        showSubmit = false;
        enableNextCta = false;
      }

      if (reqStepsValid && flagsSet !== true) {
        showSubmit = currentStep === totalSteps;
        if ($submitStepFields?.length === 0 && $nextStepFields?.length === 0) {
          enableNextCta = true;
          enableSubmitCta = true;
        } else {
          enableNextCta = false;
          enableSubmitCta = false;
        }
      } else if (
        $reqStepFields?.length &&
        reqStepsValid !== true &&
        flagsSet !== true
      ) {
        showSubmit = currentStep === totalSteps;
        enableNextCta = false;
        enableSubmitCta = false;
      }

      // if there are no fields to be validated enable the appropriate CTA
      if (
        $reqStepFields.length === 0 &&
        $nextStepFields.length === 0 &&
        $submitStepFields.length === 0
      ) {
        if (currentStep === totalSteps) {
          showSubmit = true;
          enableSubmitCta = true;
        } else {
          showSubmit = false;
          enableNextCta = true;
        }
      }

      if (showSubmit) {
        $form.classList.remove(showNextCTAClass);
        $form.classList.add(showSubmitCTAClass);
      } else {
        $form.classList.add(showNextCTAClass);
        $form.classList.remove(showSubmitCTAClass);
      }

      if (enableSubmitCta) {
        $submitCTA.removeAttribute('disabled');
        $submitCTA.classList.remove(disabledBtnClass);
      } else {
        $submitCTA.setAttribute('disabled', 'disabled');
        $submitCTA.classList.add(disabledBtnClass);
      }

      if (enableNextCta) {
        $nextCTA.removeAttribute('disabled');
        $nextCTA.classList.remove(disabledBtnClass);
      } else {
        $nextCTA.classList.add(disabledBtnClass);
        $nextCTA.setAttribute('disabled', 'disabled');
      }
    };

    // return is an element has a value or not
    const elHasVal = (
      $el: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    ) => {
      let hasValue = false;
      const elType = $el.getAttribute('type');
      if (
        elType === 'checkbox' ||
        (elType === 'radio' && $el instanceof HTMLInputElement)
      ) {
        const $elGroup =
          $el.closest('.emu-form-checkbox') ||
          ($el.closest('.emu-form-radio') as HTMLElement);
        const curCheckboxSetArr = Array.from(
          $elGroup?.querySelectorAll('input')
        ) as HTMLInputElement[];
        hasValue = curCheckboxSetArr?.findIndex($el => $el.checked) >= 0;
      } else if ($el.classList.contains('emu-form-dropdown__select')) {
        hasValue = $el.value !== '--';
      } else {
        hasValue = $el.value ? true : false;
      }

      return hasValue;
    };

    // returns an array of booleans mentioning if a list of input fields are valid or not
    const getValidityArr = (
      $fields: NodeListOf<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      if ($fields?.length) {
        let validityArr: Boolean[] = [];
        $fields.forEach($el => {
          const hasValue = elHasVal($el);

          // triggering the aaaem common validation on the element
          if ($el.getAttribute('data-required') === 'true') {
            window.Bus.emit('aaous-form:validate-element', $el);
          }

          // marking it valid if it has value and when data-invalid is not true
          validityArr.push(
            $el.getAttribute('data-invalid') !== 'true' && hasValue
          );
        });
        if (validityArr?.length) {
          return validityArr;
        }
        return [false];
      }

      return [false];
    };

    // disables an element
    const disableEl = $el => {
      const $closestWrapper = $el.closest('[data-component]');
      $closestWrapper?.classList.add(disabledFormElClass);
      const $dropdownItems = $closestWrapper?.querySelectorAll(
        '.emu-dropdown-menu__button, .emu-dropdown-menu__item'
      );
      $dropdownItems?.forEach($dropdownItem => {
        $dropdownItem?.setAttribute('tabindex', '-1');
      });
      $el.setAttribute('tabindex', '-1');
    };

    // enables an element
    const enableEl = $el => {
      const $closestWrapper = $el.closest('[data-component]');
      $closestWrapper?.classList.remove(disabledFormElClass);
      const $dropdownItems = $closestWrapper?.querySelectorAll(
        '.emu-dropdown-menu__button, .dropdown-menu-item'
      );
      $dropdownItems?.forEach($dropdownItem => {
        $dropdownItem?.setAttribute('tabindex', '0');
      });
      $el.removeAttribute('tabindex');
    };

    // when users adds value to an element, disable the rest of the fields except the required fields
    // when users remove value from an element, enable the rest of the fields and also take the user to the previous step
    const disableOrEnableStepEls = (
      $el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    ) => {
      // if it just a required field and does not point to the next or submit step, do not disable
      if (
        $el.closest('[data-required="true"]') &&
        !$el.closest(`.${nextStepSelector}`) &&
        !$el.closest(`.${submitStepSelector}`)
      ) {
        return;
      }

      // disable all the other form elements when one of the items is checked
      const hasValue = elHasVal($el);
      let shouldDisable = hasValue;

      $el
        .closest(`.${stepElMainClass}`)
        ?.classList.toggle('step-form__selected-el', hasValue);

      $el.classList.toggle('step-form__selected-el-form-element', hasValue);
      $el
        .closest('label')
        ?.classList.toggle('step-form__selected-el-form-label', hasValue);

      const $curStepEls = $form.querySelectorAll(
        `.${nextStepSelector}.${stepElClass}${currentStep}:not(.step-form__selected-el) input, .${nextStepSelector}.${stepElClass}${currentStep}:not(.step-form__selected-el) select, .${nextStepSelector}.${stepElClass}${currentStep}:not(.step-form__selected-el) textarea, 
				.${submitStepSelector}.${stepElClass}${currentStep}:not(.step-form__selected-el) input, .${submitStepSelector}.${stepElClass}${currentStep}:not(.step-form__selected-el) select, .${submitStepSelector}.${stepElClass}${currentStep}:not(.step-form__selected-el) textarea
				[data-required="true"].${stepElClass}${currentStep}:not(.step-form__selected-el) input, [data-required="true"].${stepElClass}${currentStep}:not(.step-form__selected-el) select, [data-required="true"].${stepElClass}${currentStep}:not(.step-form__selected-el) textarea`
      );

      // disabling/enabling the remaining steps
      $curStepEls.forEach($curStepEl => {
        if (shouldDisable) {
          if (!$curStepEl.closest('[data-required="true"]')) {
            disableEl($curStepEl);
          }
        } else {
          enableEl($curStepEl);
        }
      });
    };

    // returns the form data of the current step
    const getCurStepData = () => {
      const $curStepFields = $form.querySelectorAll(
        `.${stepElClass}${currentStep} input, .${stepElClass}${currentStep} textarea, .${stepElClass}${currentStep} select`
      ) as NodeListOf<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >;
      const data = {};
      $curStepFields.forEach($el => {
        if (
          $el.getAttribute('type') === 'checkbox' ||
          $el.getAttribute('type') === 'radio'
        ) {
          const $elGroup =
            $el.closest('.emu-form-checkbox') ||
            ($el.closest('.emu-form-radio') as HTMLElement);
          const curCheckboxSetArr = Array.from(
            $elGroup?.querySelectorAll('input')
          );
          curCheckboxSetArr
            ?.filter($el => $el.checked)
            .forEach($el => {
              const name = $el.name;
              if (name && $el.value) {
                data[name] = [] || data[name];
                data[name].push($el.value);
              }
            });
        } else {
          const name = $el.name;
          if (name && $el.value) {
            data[name] = [] || data[name];
            data[name].push($el.value);
          }
        }
      });
      return data;
    };

    // shows/hides prev cta based on the step user is in
    const showOrHidePrevCta = () => {
      if (!$prevCTA) {
        return;
      }

      if (currentStep === 1) {
        $form.classList.remove(showPrevCTAClass);
      } else {
        $form.classList.add(showPrevCTAClass);
      }
    };

    // whenever form element is changed, manage the ctas and the steps to be shown
    const formElChanged = $el => {
      // enable appropriate step of the form element
      const curStep = $el.getAttribute(dataStepAttr);
      if (curStep && curStep !== currentStep) {
        // move to the appropriate step
        window.Bus.emit('aaous-step-form:show-step', {
          count: parseInt(curStep, 10),
          id: formId,
        });
      }

      // disable/enable the steps
      disableOrEnableStepEls($el);

      if (showingError) {
        window.Bus.emit('aaous-step-form:hide-error-msg', { id: formId });
      }
    };

    // appends change events to the form elements
    const appendChangeEvents = () => {
      const $enterableFields = $form.querySelectorAll(
        `.${nextStepSelector} input:not([type="radio"]):not([type="checkbox"]), .${nextStepSelector} textarea, .${submitStepSelector} input:not([type="radio"]):not([type="checkbox"]), .${submitStepSelector} textarea, [data-required="true"] input:not([type="radio"]):not([type="checkbox"]), [data-required="true"] textarea`
      ) as NodeListOf<HTMLInputElement | HTMLTextAreaElement>;
      const $otherFields = $form.querySelectorAll(
        `.${nextStepSelector} input[type="radio"], .${nextStepSelector} input[type="checkbox"], .${nextStepSelector} select, .${submitStepSelector} input[type="radio"], .${submitStepSelector} input[type="checkbox"], .${submitStepSelector} select, [data-required="true"] input[type="radio"], [data-required="true"] input[type="checkbox"], [data-required="true"] select`
      ) as NodeListOf<HTMLInputElement | HTMLSelectElement>;

      $enterableFields.forEach($el => {
        $el.addEventListener('input', () => {
          formElChanged($el);
        });
      });

      $otherFields.forEach($el => {
        $el.addEventListener('change', () => {
          formElChanged($el);
        });
      });
    };

    // returns the data object
    const getDataObj = () => {
      const formData = getCurStepData();
      const fullFormData = Object.fromEntries(new FormData($form));
      return {
        currentStepData: { ...formData },
        currentStep,
        fullFormData,
        endpoint,
        id: formId,
      };
    };

    // appends events to the next, previous and submit buttons
    const appendBtnEvents = () => {
      // when next cta is clicked
      $nextCTA?.addEventListener('click', e => {
        e.preventDefault();

        // emit form data
        const data = { ...getDataObj() };
        window.Bus.emit('aaous-step-form:next-clicked', data);

        // move to next step
        window.Bus.emit('aaous-step-form:show-step', {
          count: currentStep + 1,
          id: formId,
        });
      });

      // when submit cta is clicked
      $submitCTA?.addEventListener('click', e => {
        e.preventDefault();

        // if the error message is showing, hide it
        if (showingError) {
          window.Bus.emit('aaous-step-form:hide-error-msg', { id: formId });
        }

        const data = { ...getDataObj() };
        window.Bus.emit('aaous-step-form:submit-clicked', data);
      });

      // when prev cta is clicked
      $prevCTA?.addEventListener('click', e => {
        e.preventDefault();

        const data = { ...getDataObj() };
        window.Bus.emit('aaous-step-form:prev-clicked', data);

        // move to previous step
        window.Bus.emit('aaous-step-form:show-step', {
          count: Math.max(currentStep - 1, 1),
          id: formId,
        });
      });
    };

    // toggle the loader
    const toggleLoader = show => {
      $loader?.classList.toggle(loaderActiveClass, show);
    };

    // shows success message
    const toggleSuccessMsg = show => {
      if (successMsgShowTime > 1 && successTimeout) {
        clearTimeout(successTimeout);
      }

      // hides success message after a certain period of time
      if (show === true && successMsgShowTime > 1 && formId) {
        successTimeout = setTimeout(() => {
          window.Bus.emit('aaous-step-form:reset', { id: formId });
        }, successMsgShowTime);
      }

      $form?.classList.toggle(showSuccessMsgClass, show);
    };

    // shows error message
    const toggleErrorMsg = show => {
      showingError = show;
      $form?.classList.toggle(showErrorMsgClass, show);
    };

    // appends data-step attribute to each element that has stepElClass
    const appendDataAttrsToEls = () => {
      try {
        $allStepFields.forEach($el => {
          const stepClass = Array.from(
            $el.closest(`.${stepElMainClass}`)?.classList!
          )?.find(classname => classname.indexOf(stepElClass) >= 0);
          const count = parseInt(
            (stepClass || '').replace(stepElClass, ''),
            10
          );
          if (isNaN(count) !== true) {
            $el.setAttribute(dataStepAttr, count + '');
            $el.querySelectorAll('input, select, textarea').forEach($inp => {
              $inp.setAttribute(dataStepAttr, count + '');
            });
          }
        });
      } catch (e) {
        console.warn(e);
      }
    };

    // appending events to the fields
    const appendEvents = () => {
      disableEnterInTextFields();
      appendChangeEvents();
      appendBtnEvents();
    };

    // append generic bus events
    const appendBusEvents = () => {
      if (window.Bus) {
        window.Bus.on('aaous-step-form:show-step', ({ count, id }) => {
          if (id === formId) {
            let passedCount = parseInt(count, 10);
            if (isNaN(passedCount) !== true) {
              if (count < 1) {
                count = 1;
              }
              if (count !== currentStep) {
                const data = { ...getDataObj() };
                window.Bus.emit('aaous-step-form:step-changed', data);
              }
              currentStep = count;
              addStepClassNameToForm(count);
              showOrHideElementsOfStep(count);
              showOrHideNextOrSubmitCtas();
              showOrHidePrevCta();
            }
          }
        });

        if ($loader) {
          window.Bus.on('aaous-step-form:show-loader', ({ id }) => {
            if (id === formId) {
              toggleLoader(true);
            }
          });

          window.Bus.on('aaous-step-form:hide-loader', ({ id }) => {
            if (id === formId) {
              toggleLoader(false);
            }
          });
        }

        if ($successMsg) {
          window.Bus.on('aaous-step-form:show-success-msg', ({ id }) => {
            if (id === formId) {
              toggleSuccessMsg(true);
            }
          });

          window.Bus.on('aaous-step-form:hide-success-msg', ({ id }) => {
            if (id === formId) {
              toggleSuccessMsg(false);
            }
          });
        }

        if ($errorMsg) {
          window.Bus.on('aaous-step-form:show-error-msg', ({ id }) => {
            if (id === formId) {
              toggleErrorMsg(true);
            }
          });

          window.Bus.on('aaous-step-form:hide-error-msg', ({ id }) => {
            if (id === formId) {
              toggleErrorMsg(false);
            }
          });
        }

        // complete reset for the step form
        window.Bus.on('aaous-step-form:reset', ({ id }) => {
          if (id === formId) {
            window.Bus.emit('aaous-form:reset', { id: formId });
            toggleSuccessMsg(false);

            if (successTimeout) {
              clearTimeout(successTimeout);
            }

            if (showingError) {
              window.Bus.emit('aaous-step-form:hide-error-msg', { id: formId });
            }

            $allFields.forEach($el => {
              enableEl($el);
            });

            window.Bus.emit('aaous-step-form:show-step', {
              count: 1,
              id: formId,
            });
          }
        });
      }
    };

    const initVariables = () => {
      $allTextFields = $form.querySelectorAll(
        'input:not([type="radio"]):not([type="checkbox"])'
      );
      $allFields = $form.querySelectorAll('input, textarea, select');
      $nextCTA = $form.querySelector('.step-form__cta--next')!;
      $submitCTA = $form.querySelector('.step-form__cta--submit')!;
      $prevCTA = $form.querySelector('.step-form__cta--prev')!;
      endpoint = $form?.getAttribute('action')!;
      $totalStepCountEl = $form.querySelector('#step-form-count')!;
      totalSteps = parseInt($totalStepCountEl?.value);
      $allStepFields = $form.querySelectorAll(`[class*=${stepElClass}]`);
      $loader = $form.querySelector('.step-form__loader')!;
      $successMsg = $form.querySelector('.step-form__msg--success')!;
      $errorMsg = $form.querySelector('.step-form__msg--error')!;
      successMsgShowTime = parseInt(
        $form?.getAttribute('data-success-timeout') || '0'
      );
    };

    initVariables();
    appendDataAttrsToEls();
    appendEvents();
    appendBusEvents();

    // showing the first step
    window.Bus.emit('aaous-step-form:show-step', {
      count: 1,
      id: formId,
    });
  };

  const init = () => {
    const $stepForms = document.querySelectorAll(
      '.step-form'
    ) as NodeListOf<HTMLFormElement>;
    $stepForms.forEach($stepForm => {
      initStepForm($stepForm);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();

// INTENTIONALLY COMMENTED CODE FOR REFERENCE
// (() => {
//   const init = () => {
//     const formId = 'new_form';
//     const endpoint = 'http://localhost:8000/abcd';

//     window.Bus.on('aaous-step-form:submit-clicked', e => {
//       if (e.id === formId) {
//         window.Bus.emit('aaous-step-form:show-loader', { id: formId });
//         fetch(endpoint, {
//           method: 'POST',
//           body: JSON.stringify(e.fullFormData),
//         })
//           .then(
//             resp => {
//               if (resp.ok) {
//                 window.Bus.emit('aaous-step-form:show-success-msg', {
//                   id: formId,
//                 });
//               } else {
//                 window.Bus.emit('aaous-step-form:show-error-msg', {
//                   id: formId,
//                 });
//               }
//             },
//             () => {
//               window.Bus.emit('aaous-step-form:show-error-msg', { id: formId });
//             }
//           )
//           .catch(e => {
//             console.warn(e);
//             window.Bus.emit('aaous-step-form:show-error-msg', { id: formId });
//           })
//           .finally(() => {
//             window.Bus.emit('aaous-step-form:hide-loader', { id: formId });
//           });
//       }
//     });
//   };

//   if (document.readyState === 'loading') {
//     document.addEventListener('DOMContentLoaded', init);
//   } else {
//     init();
//   }
// })();
