(() => {
  const typeMap = name => {
    switch (name) {
      case 'form-options-radio':
        return 'radio';
      case 'form-options-checkbox':
        return 'checkbox';
      case 'form-text-input':
        return 'text';
      case 'form-text-textarea':
        return 'textarea';
      case 'form-options-select':
      case 'dropdown-menu':
        return 'select';
      case 'form-recaptcha':
        return 'recaptcha';
    }
  };

  // adds events to the form, for successful submission, failed and for success-message-hidden
  const addFormSubmitResponseEvent = ($form, formId) => {
    let prevState = $form?.classList?.contains('js-response-success');
    const $ajaxContainer = $form.closest('.ajaxContainer');

    if ($ajaxContainer) {
      window.Bus.on('aaous-form:submission-success', ({ id }) => {
        if (id === formId) {
          $ajaxContainer.classList.add('form--show-success-msg');
        }
      });

      window.Bus.on('aaous-form:hide-success-message', ({ id }) => {
        if (
          id === formId &&
          $ajaxContainer.classList.contains('form--show-success-msg')
        ) {
          $ajaxContainer.classList.remove('form--show-success-msg');
          window.Bus.emit('aaous-form:success-message-hidden', {
            id: formId,
          });
        }
      });
    }

    window.Bus.on('emu-form:submit', ({ id }) => {
      if (id === formId) {
        window.Bus.emit('aaous-form:submission-success', { id });
      }
    });

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          const currentState = $form?.classList?.contains(
            'js-response-success'
          );

          if (prevState !== currentState) {
            if (prevState === true) {
              // when js-response-success class is removed hide the success message
              window.Bus.emit('aaous-form:hide-success-message', {
                id: formId,
              });
            }
            prevState = currentState;
          }

          if ($form?.classList?.contains('js-response-errors')) {
            window.Bus.emit('aaous-form:submission-fail', { id: formId });
          }
        }
      });
    });

    observer.observe($form, {
      attributes: true,
      attributeFilter: ['class'],
    });
  };

  // adds a reset bus event on form
  const addFormResetEvent = ($form, formId) => {
    let $formComponents = $form.querySelectorAll(
      '[data-component*="form-"]:not([data-component*="form-hidden-input"]), [data-component="dropdown-menu"]'
    );

    if ($formComponents?.length) {
      window.Bus.on('aaous-form:reset', ({ id }) => {
        if (id === formId) {
          $formComponents.forEach($el => {
            const type = typeMap($el.getAttribute('data-component'));
            if (type && $el.id) {
              window.Bus.emit(`emu-form-${type}:reset`, { id: $el.id });
            }

            if (type === 'recaptcha') {
              //@ts-ignore
              grecaptcha?.reset?.();
            }
          });
          $form.classList.remove('js-response-success', 'js-response-errors');
          window.Bus.emit('aaous-form:hide-success-message', { id });
        }
      });
    }
  };

  const disableElements = ($form: HTMLFormElement) => {
    const $customDisabledElems = $form.querySelectorAll(
      '.u-form-element--disabled'
    ) as NodeListOf<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
    $customDisabledElems.forEach($el => {
      const $actualEls = $el.querySelectorAll(
        'input, textarea, .emu-dropdown-menu__button'
      );
      $actualEls.forEach($actualEl => {
        $actualEl.setAttribute('tabindex', '-1');
      });
    });
  };

  const appendEvents = $form => {
    const formId = $form.id;

    if (formId) {
      addFormResetEvent($form, formId);
      addFormSubmitResponseEvent($form, formId);
    }
    disableElements($form);
  };

  // adds validation to the individual element inside a form
  const addFormElValidationEvent = () => {
    window.Bus?.on('aaous-form:validate-element', ({ el }) => {
      if (el) {
        let $el = el.getAttribute('data-component').indexOf('form-')
          ? el
          : el.closest('[data-component*="form-"]');
        if ($el) {
          const type = typeMap($el.getAttribute('data-component'));

          if (type && $el.id) {
            window.Bus.emit(`emu-form-${type}:triggerValidation`, {
              id: $el.id,
            });
          }
        }
      }
    });
  };

  // adds reset to the individual element inside a form
  const addFormElResetEvent = () => {
    window.Bus?.on('aaous-form:reset-element', ({ el }) => {
      if (el) {
        let $el = el.getAttribute('data-component').indexOf('form-')
          ? el
          : el.closest('[data-component*="form-"]');
        if ($el) {
          const type = typeMap($el.getAttribute('data-component'));

          if (type && $el.id) {
            window.Bus.emit(`emu-form-${type}:reset`, {
              id: $el.id,
            });
          }

          if (type === 'recaptcha') {
            //@ts-ignore
            grecaptcha?.reset?.();
          }
        }
      }
    });
  };

  // checkbox/radio element from aaaem-common does no provide feasibility to add anchors init
  // so created a custom structure to achieve it
  // add input-with-custom-text class to the radio
  // add a text component with input-with-custom-text__label class for label
  // add a text component with input-with-custom-text__msg class for error
  const handleInputWithCustomText = () => {
    const $els = document.querySelectorAll('.input-with-custom-text');
    $els.forEach($el => {
      addInputWithCustomTextEvents($el);
    });
  };

  const addInputWithCustomTextEvents = $el => {
    const $inpEl = $el.querySelector('input');
    const $options = $el.closest('.options');
    const $label = $options?.nextElementSibling?.querySelector(
      '.input-with-custom-text__label'
    ) as HTMLElement;
    const $msg =
      $options?.nextElementSibling?.nextElementSibling?.querySelector(
        '.input-with-custom-text__msg'
      );

    // adding default error class
    $msg?.classList.add('emu-form-checkbox__error-text');

    // adding click event on element when label is clicked
    $label?.addEventListener('click', e => {
      const target = e.target as HTMLElement;
      if (target?.getAttribute('href') === null) {
        $inpEl?.click?.();
      }
    });

    // watching attribute mutations on the element
    // when data attributes of the element are changed, changing the class names for error and label
    const callback = mutationList => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          $msg?.classList.toggle(
            'js-show',
            $el.getAttribute('data-invalid') === 'true'
          );
          $label?.classList.toggle(
            'input-with-custom-text__label--has-error',
            $el.getAttribute('data-invalid') === 'true'
          );
        }
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe($el, { attributes: true });
  };

  const handleForms = () => {
    // do not process any of this for author mode
    if (window.AAAEM?.isAuthorMode) {
      return;
    }

    const $forms = document.querySelectorAll(
      '.ajaxContainer form'
    ) as NodeListOf<HTMLFormElement>;
    $forms.forEach($form => {
      appendEvents($form);
    });

    addFormElValidationEvent();
    addFormElResetEvent();
    handleInputWithCustomText();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleForms);
  } else {
    handleForms();
  }
})();
